import { type Locale } from './type'

export default {
	homepage: {
		engineerJobLanding: {
			title: 'Kde inženýři najdou svou další oblíbenou práci',
			cta: 'Zaregistruj se jako inženýr',
		},
		highlightedJobOpenings: {
			title: 'Náhled aktuálních otevřených pozic',
			cta: 'Zobrazit všechny pro tebe relevantní pozice',
		},
		preVettedEngineers: {
			title: 'Vyberte si z našeho portfolia předem prověřených inženýrů…',
			description:
				'… ušetřete náklady a rychle postupujte ve svých projektech. Naše odbornost spočívá v oblastech hard-tech inženýrství, jako je výroba, výzkum a vývoj, životní prostředí, automatizace, chemie, energetika, elektrotechnika a IT. Začněte hned a zveřejněte pozici se spacebrains zdarma.',
			primaryAction: 'Zarezervujte si hovor',
			secondaryAction: 'Zjistit více',
		},
		engineerFocus: {
			title: 'Kde se inženýrům skutečně rozumí - přidej se k nám',
			description:
				'spacebrains se spojuje se společnostmi, které zastávají engineer-centric pracovní podmínky a freelancingové možnosti ve světě hard-tech inženýrství. Pracuješ ve strojírenské, výrobní, elektrotechnické, chemické nebo environmentální oblasti nebo někde mezi tím? Přihlas se nyní, a pomůžeme ti přetavit tvou vysněnou práci na realitu.',
			primaryAction: 'Zaregistruj se',
			secondaryAction: 'Zjistit více',
		},
		header: {
			links: {
				home: 'Domů',
				forEngineers: 'Pro Inženýry',
				forCompanies: 'Pro Společnosti',
				contact: 'Kontakt',
				jobs: 'Pracovní nabídky',
			},
		},
		footer: {
			billing: 'Fakturační údaje',
			description:
				'Firmy mají problém zaměstnat a udržet si talenty, a tak zápasí s probíhajícími projekty nebo nedokážou inovovat. Nabízíme řešení. Společně můžeme vytvořit životně důležitý a prosperující ekosystém pracovního trhu, ze kterého budou mít užitek všichni.',
			office: 'Adresa kanceláře',
			links: {
				cookies: 'Cookies',
				privacy: 'Zpracování osobních údajů',
				terms: 'Podmínky používání',
			},
			socials: 'Sociální sítě',
		},
	},
	title: 'Kde inženýři najdou svou další oblíbenou práci',
	iAmInterested: 'Mám zájem',
	iAmNotInterested: 'Nemám zájem',
	profile: 'Profil',
	logout: 'Odhlásit se',
	firstName: 'Jméno',
	lastName: 'Příjmení',
	saveChanges: 'Uložit změny',
	changeEmail: 'Změnit email',
	changePassword: 'Změnit heslo',
	enable2FA: 'Povolit 2 faktorovou autentifikaci',
	enabled2FA: '2FA je povolena',
	createPassword: 'Vytvořit heslo',
	deleteAccount: 'Smazat účet',
	areYouSure: 'Jste si jistý?',
	sendConfirmation: 'Poslat potvrzení',
	newEmail: 'Nový email',
	emailConfirmationMessage:
		'Na novou e-mailovou adresu obdržíte e-mail k potvrzení.',
	oldEmailNotice: 'Na vaši starou adresu vám bude zasláno e-mailové upozornění',
	twoFactorAuthenticationMessage:
		'Ještě jste nepovolili dvoufaktorové ověření.',
	twoFactorAuthenticatorInfo1:
		'Dvoufaktorové ověření přidává do vašeho účtu další úroveň zabezpečení. Budete muset zadat kód z ověřovací aplikace, například',
	twoFactorAuthenticatorInfo2: 'pro přihlášení.',
	twoFactorAuthenticatorNotEnabled:
		'Ještě jste nepovolili dvoufaktorové ověření.',
	confirmNewPassword: 'Potvrďte nové heslo',
	currentPassword: 'Aktuální heslo',
	newPassword: 'Nové heslo',
	cancel: 'Zrušit',
	confirm: 'Potvrdit',
	change: 'Změnit',
	logIn: 'Přihlásit se',
	rememberMe: 'Zapamatovat si mě',
	forgotPasswordQuestion: 'Zapomněli jste heslo?',
	createAccount: 'Vytvořit účet',
	welcomeBack: 'Vítejte zpět',
	enterDetails: 'Prosím, zadejte své údaje.',
	email: 'Email',
	password: 'Heslo',
	newHere: 'Jsi tady nový?',
	letsStart: 'Začněme!',
	submit: 'Odeslat',
	code: 'Kód',
	emailVerificationCode: 'Poslali jsme vám kód pro ověření e-mailové adresy.',
	checkYourEmail: 'Zkontrolujte svůj e-mail',
	welcomeAboard: 'Vítejte na palubě!',
	pleaseEnterDetails: 'Prosím, zadejte své údaje.',
	createAnAccount: 'Vytvořte si účet',
	confirmPassword: 'Potvrďte heslo',
	agreeTos:
		'Souhlasím s <termsLink>podmínkami používání</termsLink> a <privacyLink>zásadami ochrany osobních údajů</privacyLink>',
	welcome: 'Vítejte',
	signupThankYouMessage: 'Děkujeme za registraci!',
	requireLoginMessage:
		'Pro zobrazení více pracovních pozic se musíte přihlásit.',
	forgotPassword: 'Zapomenuté heslo',
	forgotPasswordMessage: 'Žádný problém, pošleme vám pokyny k obnovení.',
	recoverPassword: 'Obnovit heslo',
	backToLogin: 'Zpět k přihlášení',
	requiredError: '{{field}} je povinné',
	jobDetail: {
		chooseFile: 'Vybrat soubor',
		description: 'Popis',
		details: 'Detaily práce',
		location: 'Lokalita',
		salary: 'Plat',
		contractType: 'Typ smlouvy',
		commitment: 'Úvazek',
		experienceLevel: 'Úroveň zkušeností',
		qualifications: 'Kvalifikace',
		experience: 'Zkušenosti',
		benefits: 'Benefity',
		languages: 'Jazyky',
		tags: 'Značky',
		companyInfo: 'Informace o společnosti',
		companyName: 'Název společnosti',
		industries: 'Odvětví',
		companyType: 'Typ společnosti',
		companySize: 'Velikost společnosti',
		companyLocation: 'Sídlo společnosti',
		alreadyApplied: 'Žádost odeslána',
		applyNow: 'Ucházet se',
		loginToApply: 'Pro podání žádosti se prosím přihlaste',
		notSpecified: 'Nespecifikováno',
		attachResume: 'Připojit životopis',
		defaultResumeAttached: 'Výchozí životopis připojen',
		attachNewResume: 'Připojit nový životopis',
	},
	jobTitle: 'Název pozice',
	company: 'Společnost',
	applicationDate: 'Datum přihlášení',
	noApplications: 'Zatím jste se nepřihlásili na žádná pracovní místa.',
	settings: {
		applications: 'Žádosti',
		email: 'Email',
		password: 'Heslo',
		photo: 'Fotka',
		profile: 'Profil',
		resume: 'Životopis',
	},
	preferredLanguage: 'Preferovaný jazyk',
	preferredLanguages: {
		en: 'Angličtina',
		sk: 'Slovenčina',
		cz: 'Čeština',
	},
	idealSalary: 'Ideální plat',
	minimumSalary: 'Minimální plat',
	accompanyingText: 'Průvodní text',
	contractType: 'Typ smlouvy',
	noticePeriod: 'Výpovědní lhůta',
	navigation: 'Navigace',
	referral: 'Doporučení',
	cookieConsent: {
		title: 'Používáme cookies',
		description:
			'Používáme cookies, abychom zajistili, že na naší webové stránce získáte nejlepší zážitek. Pro více informací o tom, jak používáme cookies, si přečtěte naše zásady používání cookies.',
		agreement: 'Kliknutím na "Přijmout" souhlasíte s používáním cookies.',
		learnMore: 'Zjistit více.',
		accept: 'Přijmout',
		decline: 'Odmítnout',
	},
	engineer: {
		engineer: 'Inženýr',
		headerTitle: 'Udělej další krok ve své inženýrské kariéře',
		headerDescription:
			'Zaregistruj se ještě dnes a odemkni svět příležitostí přizpůsobených právě tobě. Zjisti, jak ti spacebrains může pomoci dosáhnout tvých profesionálních cílů a spojit tě se zaměstnavateli, kteří si budou cenit tvé schopnosti a zkušenosti.',
		headerCTA: 'Registrovat jako inženýr',
		subHeader: 'Naše nabídka a závazky vůči inženýrům',
		offers: [
			{
				title: 'Příležitosti šité na míru',
				description:
					'Ať už máš zájem o TPP, freelancerské nebo interim pozice, naše platforma slouží všem inženýrům. Zajišťujeme, aby každá pracovní příležitost – od konstruktéra a procesního inženýra až po automatizaci, kontrolu kvality a IT – byla ušita na míru tvým schopnostem a kariérním cílům.',
			},
			{
				title: 'Transparentní proces registrace',
				description:
					'V našem náborovém procesu si ceníme transparentnosti a spravedlnosti. Ve spacebrains komunikujeme vždy jasně a to na každém kroku tvé cesty hledání budoucnosti. Po registraci si budeš moci prohlížet všechny aktuální příležitosti a ucházet se jen o ty, které ti vyhovují.',
			},
			{
				title: 'Komunita a odborný růst',
				description:
					'Být členem spacebrains znamená stát se součástí inženýrské komunity. Naším posláním je vytvářet prostor pro inženýry, kde se můžeme jeden od druhého učit a společně růst. Přidej se ke spacebrains ještě dnes a brzy získáš přístup ke zdrojům, školícím materiálům a dalším poznatkům z relevantních odvětví, které ti pomohou dosáhnout ve tvé kariéře více.',
			},
			{
				title: 'Neprůstřelné soukromí a bezpečnost',
				description:
					'Důvěrnost tvých osobních a profesionálních informací je u nás na prvním místě. Bez tvého výslovného souhlasu se prakticky nikdo nedozví, že jsi u nás zaregistrován. To ti umožňuje plnou kontrolu nad používáním a distribucí tvých údajů.',
			},
		],
		joinSpacebrains: {
			title: 'Připoj se k spacebrains a buduj svou budoucnost už teď!',
			cta: 'Registrovat jako inženýr',
		},
		moreInfo: {
			title: 'Potřebuješ více informací?',
			description:
				'Ozvi se nám, a my ti pomůžeme začít tvou cestu se spacebrains. Využij náš kontaktní formulář nebo si s námi zarezervuj 15minutový hovor.',
			primaryAction: 'Napiš nám',
			secondaryAction: 'Zavolejme si',
		},
	},
	companies: {
		header: {
			title: 'Přinášíme bezstarostný hiring pro technické manažery',
			description:
				'Přeskočte dlouhé náborové cykly a získejte přístup k rostoucí komunitě inženýrů a lídrů s naší end-to-end platformou pro nábor a outsourcing.',
			cta: 'Konzultace zdarma',
		},
		offers: [
			{
				title: 'Efektivní hiring',
				description:
					'Od definování popisu práce až po konečný výběr, náš proces zajišťuje, že strávíte méně času náborovou administrací a více času vašimi hlavními obchodními aktivitami.',
			},
			{
				title: 'Technické posouzení',
				description:
					'Najít shodu v životopisu je snadné, zato skutečné technické posouzení je obtížné. Nabízíme přístup k bezkonkurenčnímu technickému prověření kandidáta pomocí našeho jedinečného peer-to-peer procesu hodnocení.',
			},
			{
				title: 'Závazek ke kvalitě',
				description:
					'Náš komplexní proces prověřování zahrnuje hodnocení potenciálu a soft-skills, čímž zvýšíme šance na dlouhodobě úspěšný výběr a prosperování kandidáta ve vašem prostředí.',
			},
			{
				title: 'Pokračující podpora',
				description:
					'Náš vztah nekončí pracovní smlouvou. Poskytujeme nepřetržitou podporu a poradenství, které vám pomohou zajistit bezproblémovou integraci inženýra do vašeho týmu.',
			},
		],
		faqTitle: 'Často kladené dotazy',
		faq: [
			{
				title: 'Na jaká odvětví se zaměřujete?',
				description:
					'Vynikáme ve spojování firem s inženýry a lídry s odborností, kteří obvykle vystudovali technické univerzity – strojírenství, chemii, robotiku, elektroniku a IT, elektrotechniku, procesní nebo stavební inženýrství. To znamená, že se primárně zaměřujeme na odvětví „fyzického“ inženýrství, jako je automobilový průmysl, letecký průmysl, petrochemie, robotika a automatizace, výroba, materiály, zdravotnictví, biotechnologie nebo energetika.',
			},
			{
				title: 'Spolupracujete jen se OSVČ inženýry/živnostníkmi?',
				description:
					'Ne. Netlačíme firmy ani inženýry k žádné formě spolupráce. Jedním z prvních kroků je porozumět vašim obchodním operacím a preferovanému pracovnímu režimu budoucího inženýra – ať už jde o trvalý pracovní poměr, smlouvu na částečný úvazek, práci jako OSVČ, interim nebo cokoliv mezi tím. Rádi Vám pomůžeme při rozhodování o typu spolupráce.',
			},
			{
				title: 'Jak dlouho trvá výběrový proces?',
				description:
					'V případě smluv na dobu neurčitou celý proces obvykle trvá v závislosti na okolnostech do 30 dnů. V případě freelance kontraktu vám víme relevantní kandidáty najít obvykle do dvou týdnů. Přesný čas závisí na mnoha faktorech, proto jej nelze zaručit.',
			},
			{
				title: 'Jak se spacebrains liší od jiných personálních agentur?',
				description:
					'Náboroví pracovníci v personálních agenturách obvykle řídí nábory ve velkém množství – od pracovníků na změny, přes techniky, elektrikáře a příležitostně i inženýry. Jejich procesy nejsou zaměřeny na hluboké technické porozumění a hodnocení, takže kvalita kandidátů na inženýrské pozice se může lišit. Náš proces je založen na interní technické expertíze a peer-to-peer hodnocení inženýrů a technických lídrů, které vám přinášejí kandidáty nejvyšší kvality dostupných na trhu práce.',
			},
			{
				title: 'Jak se spacebrains liší od tradičních pracovních portálů?',
				description:
					'Pracovní portály jako jobs.cz vám účtují za vypsání inzerátu bez jakékoli záruky nalezení dobrého kandidáta. Musíte si sami zpracovat všechny kandidáty, vést s nimi pohovory, posoudit jejich odbornost a udržovat komunikaci s relevantními kandidáty, dokud je pozice stále otevřená. To může klidně zabrat i desítky hodin. V spacebrains se zaměřujeme na komplexní nábor, takže vše uděláme za vás, a pokud nenajdeme shodu, proces je pro vás bezplatný.',
			},
			{
				title: 'Jak funguje cenotvorba?',
				description:
					'Typ odměnovny závisí zejména na typu spolupráce. Zaručujeme vám, že vám budeme účtovat poplatky jen tehdy, pokud vám úspěšně přiřadíme vhodného inženýra. Jinými slovy, příprava popisu práce, umístění inzerátu na našem portálu, hodnocení kandidátů a pohovory jsou bezplatné.',
			},
		],
		reachOut: {
			title: 'Ozvěte se nám pro více informací',
			description:
				'Kontaktujte nás a získejte podrobnosti o tom, jak vám můžeme pomoci v konkrétních inženýrských disciplínách, nebo chcete-li prodiskutovat své jedinečné požadavky. Jsme připraveni pomoci vám vybudovat silnější a schopnější inženýrský tým.',
			primaryAction: 'Napište nám',
			secondaryAction: 'Zavolejme si',
		},
	},
	type: 'Typ',
	message: 'Zpráva',
	thankYou: 'Děkujeme',
	thankYouMessage:
		'Děkujeme, že jste nás kontaktovali. Vaši zprávu jsme obdrželi a brzy se vám ozveme.',
	backToHome: 'Zpět na hlavní stránku',
	agree:
		'Souhlasím s <termsLink>podmínkami používání</termsLink> a <privacyLink>zásadami ochrany osobních údajů</privacyLink>',
	phone: 'Telefon',
	position: 'Pozice',
	validationMessages: {
		required: 'Toto pole je povinné',
		firstNameRequired: 'Křestní jméno je povinné',
		lastNameRequired: 'Příjmení je povinné',
		messageRequired: 'Zpráva je povinná',
		typeRequired: 'Typ je povinen',
		agreementRequired: 'Souhlas je povinen',
		emailRequired: 'Email je povinen',
		emailInvalid: 'Email je neplatný',
		passwordRequired: 'Heslo je povinné',
		passwordTooShort: 'Heslo musí mít alespoň 6 znaků',
		passwordTooLong: 'Heslo může mít maximálně 100 znaků',
		nameRequired: 'Jméno je povinné',
		nameTooShort: 'Jméno musí mít alespoň 3 znaky',
		nameTooLong: 'Jméno může mít maximálně 40 znaků',
		emailTooLong: 'Email může mít maximálně 100 znaků',
		emailTooShort: 'Email musí mít alespoň 3 znaky',
		passwordsMustMatch: 'Hesla se musí shodovat',
		generic: 'Něco se pokazilo',
		emailNotFound: 'Email nebyl nalezen',
		termsConditionsAgreement: 'Musíte souhlasit s podmínkami používání',
		incorrectPassword: 'Nesprávne heslo',
		contractTypeRequired: 'Typ smlouvy je povinen',
		noticePeriodRequired: 'Výpovědní lhůta je povinná',
		idealSalaryRequired: 'Ideální plat je povinen',
		minimumSalaryRequired: 'Minimální plat je povinen',
		accompanyingTextRequired: 'Průvodní text je povinen',
		resumeRequired: 'Životopis je povinen',
		imageRequired: 'Fotka je povinná',
		imageTooLarge: 'Fotka musí mít méně než 3MB',
		resumeTooLarge: 'Životopis musí mít méně než 5MB',
		resumeInvalidType: 'Životopis musí být ve formátu PDF',
	},
	otpEmail: {
		subject: 'Zde je tvůj verifikační kód',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		verificationCode: 'Tvůj ověřovací kód pro dokončení registrace:',
		verificationLink:
			'Pro dokončení verifikace můžeš použít i následující odkaz:',
		verifyButton: 'Verifikace emailu',
		footer:
			'Tento e-mail jsi obdržel, protože někdo použil tvou e-mailovou adresu k registraci účtu na platformě spacebrains.tech. Pokud jsi to nebyl ty, ignoruj ​​tento e-mail.',
		copyright: 'copyright 2024 © spacebrains. Všechna práva vyhrazena.',
	},
	contactPage: {
		companies: {
			description:
				'Zastupuji firmu (jako manažer, HR specialista, atd.) a chci se dozvědět více o vašich službách',
			heading: 'Píši jako zástupce firmy',
		},
		engineers: {
			description:
				'Jsem inženýr, freelancer nebo technický lídr a chci se dozvědět více o příležitostech pro mě',
			heading: 'Píši svým jménem',
		},
	},
	jobsPage: {
		title: 'Aktuálně dostupné pracovní pozice',
		findOutMore: 'Zjistit více',
	},
	loginToViewMore: 'Přihlásit se k zobrazení více',
	onboardingEmail: {
		subject: 'Registrace dokončena',
		greeting: 'Ahoj {{firstName}},',
		welcomeMessage: 'Vítej v spacebrains! 🚀',
		introduction:
			'My, tým spacebrains, jsme rádi, že tě máme na palubě. Dovol mi využít této příležitosti a představit nás trochu víc.',
		platformDescription:
			'spacebrains je end-to-end platforma, která spojuje inženýry a technické lídry se společnostmi, které se snaží obsadit technické pozice. Platformu spacebrains jsme spustili teprve nedávno, takže to, co na platformě vidíš, je jen zlomek všeho, co pro vás připravujeme. Ale neboj se, budeme tě informovat, představíme-li nějaké nové funkce, o kterých bys měl/a vědět.',
		uploadCVPrompt:
			'In the meantime, nahrál/a jsi svůj životopis na dashboard svého profilu? Pokud ne, toto tlačítko tě přesměruje na místo pro nahrání životopisu.',
		uploadCVButton: 'Nahrát CV',
		browseJobsPrompt:
			'Nebo možná chceš hned skočit na prohlížení aktuálních pozic? Klidně rovnou přes tento odkaz zde',
		browseJobsButton: 'Přejít na seznam všech pozic',
		gratitudeMessage:
			'A jsme skutečně vděční za každou jednu registraci u nás - ať už jsi inženýr/ka, freelancer/ka nebo technický/á vedoucí, doufáme, že ti budeme moci sloužit stejně jako ostatním inženýrům, přičemž budeme dbát na přirozené rozdíly mezi každým jedním z vás.',
		contactPrompt:
			'Zůstáváme v kontaktu a pokud budeš cokoliv potřebovat, odpověz na tento e-mail.',
		closing: 'S pozdravem,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			'Tento e-mail jste obdrželi, protože někdo dal vaši e-mailovou adresu k registraci účtu na platformě spacebrains.tech. Pokud jste to nebyli vy, ignorujte prosím tento e-mail.',
		copyright: 'copyright 2024 © spacebrains. Všechna práva vyhrazena.',
	},
	noResumeUploaded: 'Žádný životopis nahraný',
	currentResume: 'Aktuální životopis: {{filename}} (nahraný dne {{createdAt}})',
	resumeDeletedDescription: 'Váš životopis byl vymazán',
	resumeDeletedTitle: 'Životopis vymazán',
	resumeUpdatedDescription: 'Váš životopis byl aktualizován',
	resumeUpdatedTitle: 'Životopis aktualizován',
	delete: 'Vymazat',
	download: 'Stáhnout',
	saveResume: 'Uložit životopis',
	upload: 'Nahrát',
	resumeUploadPrompt:
		'Klepnutím na uložit životopis nahrát následující životopis: {{filename}}',
	partnerWithUs:
		'Spojte se s námi a připojte se k dalším společnostem, které myslí na budoucnost',
	applicationEmail: {
		subject: 'Vaše žádost o pracovní pozici',
		greeting: 'Ahoj {{name}},',
		applicationSubmitted:
			'Vaše žádost o pracovní pozici {{jobTitle}} ve společnosti {{companyName}} byla úspěšně odeslána. 💪',
		applicationSummary: 'Zde je sumář vaší žádosti:',
		positionName: 'Název pozice: {{jobTitle}}',
		contractType: 'Typ smlouvy: {{contractType}}',
		noticePeriod: 'Výpovědní lhůta: {{noticePeriod}}',
		idealSalary: 'Ideální plat: {{idealSalary}}',
		minSalary: 'Minimální plat: {{minSalary}}',
		accompanyingText: 'Průvodní text:',
		reviewApplication: 'Vaši žádost posoudíme a brzy se vám ozveme.',
		closing: 'S pozdravem,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			'Tento e-mail jste obdrželi, protože jste odeslali žádost o zaměstnání na platformě spacebrains.tech.',
		copyright: 'copyright 2024 © spacebrains. Všechna práva vyhrazena.',
	},
	jobList: {
		loginToViewMore: 'Pro seznam všech pozic je potřebné přihlášení',
	},
	seo: {
		home: {
			title: 'spacebrains',
			description: 'Pomáháme inženýrům najít jejich další oblíbenou práci.',
		},
		engineers: {
			title: 'Pro inženýry | spacebrains',
			description: 'Pomáháme inženýrům najít jejich další oblíbenou práci.',
		},
		companies: {
			title: 'Pro společnosti | spacebrains',
			description: 'Pomáháme společnostem najít nejlepší inženýry.',
		},
		contact: {
			title: 'Kontaktujte nás | spacebrains',
			description: 'Pomáhame inžinierom nájsť ich ďalšiu obľubenú prácu.',
		},
	},
} satisfies Locale
