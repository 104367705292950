import { type Locale } from './type'

export default {
	homepage: {
		engineerJobLanding: {
			title: 'Kde inžinieri nájdu svoju ďalšiu obľúbenú prácu',
			cta: 'Zaregistrujte sa ako inžinier',
		},
		highlightedJobOpenings: {
			title: 'Náhľad aktuálnych otvorených pozícií',
			cta: 'Zobraziť všetky pre teba relevantné pozície',
		},
		preVettedEngineers: {
			title: 'Vyberte si z nášho portfólia vopred preverených inžinierov…',
			description:
				'… ušetrite náklady a rýchlo napredujte vo svojich projektoch. Naša odbornosť spočíva v oblastiach hard-tech inžinierstva, ako je výroba, výskum a vývoj, životné prostredie, automatizácia, chémia, energetika, elektrotechnika a IT. Začnite hneď a zverejnite pozíciu so spacebrains zadarmo.',
			primaryAction: 'Zarezervujte si hovor',
			secondaryAction: 'Zistiť viac',
		},
		engineerFocus: {
			title: 'Kde sa inžinierom skutočne rozumie - pridaj sa k nám',
			description:
				'spacebrains sa spája so spoločnosťami, ktoré zastávajú engineer-centric pracovné podmienky a freelancingové možnosti vo svete hard-tech inžinierstva. Pracuješ v strojárskej, výrobnej, elektrotechnickej, chemickej alebo environmentálnej oblasti alebo niekde medzi tým? Prihlás sa teraz, a pomôžeme ti pretaviť tvoju vysnívanú prácu na realitu.',
			primaryAction: 'Zaregistrujte sa',
			secondaryAction: 'Zistiť viac',
		},
		header: {
			links: {
				home: 'Domov',
				forEngineers: 'Pre Inžinierov',
				forCompanies: 'Pre Spoločnosti',
				contact: 'Kontakt',
				jobs: 'Pracovné ponuky',
			},
		},
		footer: {
			billing: 'Fakturačné údaje',
			description:
				'Firmy majú problém zamestnať a udržať si talenty, a tak zápasia s prebiehajúcimi projektmi alebo nedokážu inovovať. Ponúkame riešenie. Spoločne môžeme vytvoriť životne dôležitý a prosperujúci ekosystém pracovného trhu, z ktorého budú mať úžitok všetci.',
			office: 'Adresa kancelárie',
			links: {
				cookies: 'Cookies',
				privacy: 'Spracovanie osobných údajov',
				terms: 'Podmienky používania',
			},
			socials: 'Sociálne siete',
		},
	},
	title: 'Kde inžinieri nájdu svoju ďalšiu obľúbenú prácu',
	iAmInterested: 'Mám záujem',
	iAmNotInterested: 'Nemám záujem',
	profile: 'Profil',
	logout: 'Odhlásiť sa',
	firstName: 'Meno',
	lastName: 'Priezvisko',
	saveChanges: 'Uložiť zmeny',
	changeEmail: 'Zmeniť email',
	changePassword: 'Zmeniť heslo',
	enable2FA: 'Povoliť 2 faktorovú autentifikáciu',
	enabled2FA: '2FA je povolená',
	createPassword: 'Vytvoriť heslo',
	deleteAccount: 'Zmazať účet',
	areYouSure: 'Ste si istý?',
	sendConfirmation: 'Poslať potvrdenie',
	newEmail: 'Nový email',
	emailConfirmationMessage:
		'Na novú e-mailovú adresu obdržíte e-mail na potvrdenie.',
	oldEmailNotice: 'Na vašu starú adresu vám bude zaslané e-mailové upozornenie',
	twoFactorAuthenticationMessage: 'Ešte ste nepovolili dvojfaktorové overenie.',
	twoFactorAuthenticatorInfo1:
		'Dvojfaktorové overenie pridáva do vášho účtu ďalšiu úroveň zabezpečenia. Budete musieť zadať kód z overovacej aplikácie, napríklad ',
	twoFactorAuthenticatorInfo2: ' pre prihlásenie.',
	twoFactorAuthenticatorNotEnabled:
		'Ešte ste nepovolili dvojfaktorové overenie.',
	confirmNewPassword: 'Potvrďte nové heslo',
	currentPassword: 'Aktuálne heslo',
	newPassword: 'Nové heslo',
	cancel: 'Zrušiť',
	confirm: 'Potvrdiť',
	change: 'Zmeniť',
	logIn: 'Prihlásiť sa',
	rememberMe: 'Zapamätať si ma',
	forgotPasswordQuestion: 'Zabudli ste heslo?',
	createAccount: 'Vytvoriť účet',
	welcomeBack: 'Vitajte späť',
	enterDetails: 'Prosím, zadajte svoje údaje.',
	email: 'Email',
	password: 'Heslo',
	newHere: 'Ste tu nový?',
	letsStart: 'Začnime!',
	submit: 'Odoslať',
	code: 'Kód',
	emailVerificationCode: 'Poslali sme vám kód na overenie e-mailovej adresy.',
	checkYourEmail: 'Skontrolujte svoj e-mail',
	welcomeAboard: 'Vitajte na palube!',
	pleaseEnterDetails: 'Prosím, zadajte svoje údaje.',
	createAnAccount: 'Vytvorte si účet',
	confirmPassword: 'Potvrďte heslo',
	agreeTos:
		'Súhlasíte s našimi podmienkami služby a zásadami ochrany osobných údajov?',
	welcome: 'Vitajte',
	signupThankYouMessage: 'Ďakujeme za registráciu!',
	requireLoginMessage:
		'Pre zobrazenie viac pracovných pozícií sa musíte prihlásiť.',
	forgotPassword: 'Zabudnuté heslo',
	forgotPasswordMessage: 'Žiadny problém, pošleme vám pokyny na obnovenie.',
	recoverPassword: 'Obnoviť heslo',
	backToLogin: 'Späť na prihlásenie',
	requiredError: '{{field}} je povinné',
	jobDetail: {
		chooseFile: 'Vybrať súbor',
		description: 'Popis',
		details: 'Detaily práce',
		location: 'Lokalita',
		salary: 'Plat',
		contractType: 'Typ zmluvy',
		commitment: 'Úväzok',
		experienceLevel: 'Úroveň skúseností',
		qualifications: 'Kvalifikácie',
		experience: 'Skúsenosti',
		benefits: 'Benefity',
		languages: 'Jazyky',
		tags: 'Značky',
		companyInfo: 'Informácie o spoločnosti',
		companyName: 'Názov spoločnosti',
		industries: 'Odvetvia',
		companyType: 'Typ spoločnosti',
		companySize: 'Veľkosť spoločnosti',
		companyLocation: 'Sídlo spoločnosti',
		alreadyApplied: 'Žiadosť odoslaná',
		applyNow: 'Uchádzať sa',
		loginToApply: 'Pre podanie žiadosti sa prosím prihláste',
		notSpecified: 'Nešpecifikované',
		attachResume: 'Pripojiť životopis',
		defaultResumeAttached: 'Predvolený životopis pripojený',
		attachNewResume: 'Pripojiť nový životopis',
	},
	jobTitle: 'Názov pozície',
	company: 'Spoločnosť',
	applicationDate: 'Dátum prihlásenia',
	noApplications: 'Zatiaľ ste sa neprihlásili na žiadne pracovné miesta.',
	settings: {
		applications: 'Žiadosti',
		email: 'Email',
		password: 'Heslo',
		photo: 'Fotka',
		profile: 'Profil',
		resume: 'Životopis',
	},
	preferredLanguage: 'Preferovaný jazyk',
	preferredLanguages: {
		en: 'Angličtina',
		sk: 'Slovenčina',
		cz: 'Čeština',
	},
	idealSalary: 'Ideálny plat',
	minimumSalary: 'Minimálny plat',
	accompanyingText: 'Sprievodný text',
	contractType: 'Typ zmluvy',
	noticePeriod: 'Výpovedná lehota',
	navigation: 'Navigácia',
	referral: 'Odporúčanie',
	cookieConsent: {
		title: 'Používame cookies',
		description:
			'Používame cookies, aby sme zaistili, že na našej webovej stránke získate najlepší zážitok. Pre viac informácií o tom, ako používame cookies, si prečítajte naše zásady používania cookies.',
		agreement: 'Kliknutím na "Prijať" súhlasíte s používaním cookies.',
		learnMore: 'Zistiť viac.',
		accept: 'Prijať',
		decline: 'Odmietnuť',
	},
	engineer: {
		engineer: 'Inžinier',
		headerTitle: 'Urob ďalší krok vo svojej inžinierskej kariére',
		headerDescription:
			'Zaregistruj sa ešte dnes a odomkni svet príležitostí prispôsobených práve tebe. Zisti, ako ti spacebrains môže pomôcť dosiahnuť tvoje profesionálne ciele a spojiť ťa so zamestnávateľmi, ktorí si budú ceniť tvoje schopnosti a skúsenosti.',
		headerCTA: 'Registrovať ako inžinier',
		subHeader: 'Naša ponuka a záväzky voči inžinierom',
		offers: [
			{
				title: 'Príležitosti šité na mieru',
				description:
					'Či už máš záujem o TPP, freelancerské alebo interim pozície, naša platforma slúži všetkým inžinierom. Zabezpečujeme, aby každá pracovná príležitosť – od konštruktéra a procesného inžiniera až po automatizáciu, kontrolu kvality a IT – bola ušitá na mieru tvojim schopnostiam a kariérnym cieľom.',
			},
			{
				title: 'Transparentný proces registrácie',
				description:
					'V našom náborovom procese si ceníme transparentnosť a spravodlivosť. V spacebrains komunikujeme vždy jasne a to na každom kroku tvojej cesty hľadania budúcnosti. Po registrácii si budeš môcť prezerať všetky aktuálne príležitosti a uchádzať sa o iba o tie, ktoré ti vyhovujú.',
			},
			{
				title: 'Komunita a odborný rast',
				description:
					'Byť členom spacebrains znamená stať sa súčasťou inžinierskej komunity. Naším poslaním je vytvárať priestor pre inžinierov, kde sa môžeme jeden od druhého učiť a spoločne rásť. Pridaj sa k spacebrains ešte dnes a čoskoro získaš prístup k zdrojom, školiacim materiálom a ďalším poznatkom z relevantných odvetví, ktoré ti pomôžu dosiahnuť v tvojej kariére viac.',
			},
			{
				title: 'Nepriestrelné súkromie a bezpečnosť',
				description:
					'Dôvernosť tvojich osobných a profesionálnych informácií je u nás na prvom mieste. Bez tvojho výslovného súhlasu sa prakticky nikto nedozvie, že si u nás zaregistrovaný. To ti umožňuje plnú kontrolu nad používaním a distribúciou tvojich údajov.',
			},
		],
		joinSpacebrains: {
			title: 'Pripoj sa k spacebrains a buduj svoju budúcnosť už teraz!',
			cta: 'Registrovať ako inžinier',
		},
		moreInfo: {
			title: 'Potrebuješ viac informácií?',
			description:
				'Ozvi sa nám, a my ti pomôžeme začať tvoju cestu so spacebrains. Využi náš kontaktný formulár alebo si s nami zarezervuj 15-minútový hovor.',
			primaryAction: 'Napíš nám',
			secondaryAction: 'Zavolajme si',
		},
	},
	companies: {
		header: {
			title: 'Prinášame bezstarostný hiring pre technických manažérov',
			description:
				'Preskočte dlhé náborové cykly a získajte prístup k rastúcej komunite inžinierov a lídrov s našou end-to-end platformou pre nábor a outsourcing.',
			cta: 'Konzultácia zdarma',
		},
		offers: [
			{
				title: 'Efektívny hiring',
				description:
					'Od definovania popisu práce až po konečný výber, náš proces zaisťuje, že strávite menej času náborovou administráciou a viac času vašimi hlavnými obchodnými aktivitami.',
			},
			{
				title: 'Technické posúdenie',
				description:
					'Nájsť zhodu v životopise je ľahké, zato skutočné technické posúdenie je ťažké. Ponúkame prístup k bezkonkurenčnému technickému prevereniu kandidáta pomocou nášho jedinečného peer-to-peer procesu hodnotenia.',
			},
			{
				title: 'Záväzok ku kvalite',
				description:
					'Náš komplexný proces preverovania zahŕňa hodnotenie potenciálu a soft-skills, čím zvýšime šance na dlhodobo úspešný výber a prosperovanie kandidáta vo vašom prostredí.',
			},
			{
				title: 'Pokračujúca podpora',
				description:
					'Náš vzťah nekončí pracovnou zmluvou. Poskytujeme nepretržitú podporu a poradenstvo, ktoré vám pomôžu zabezpečiť bezproblémovú integráciu inžiniera do vášho tímu.',
			},
		],
		faqTitle: 'Často kladené otázky',
		faq: [
			{
				title: 'Na aké odvetvia sa zameriavate?',
				description:
					'Vynikáme v spájaní firiem s inžiniermi a lídrami s odbornosťou, ktorí zvyčajne vyštudovali technické univerzity – strojárstvo, chémiu, robotiku, elektroniku a IT, elektrotechniku, procesné alebo stavebné inžinierstvo. To znamená, že sa primárne zameriavame na odvetvia “fyzického” inžinierstva, ako je automobilový priemysel, letecký priemysel, petrochémia, robotika a automatizácia, výroba, materiály, zdravotníctvo, biotechnológia alebo energetika.',
			},
			{
				title: 'Spolupracujete len so SZČO inžiniermi/živnostníkmi?',
				description:
					'Nie. Netlačíme firmy ani inžinierov k žiadnej forme spolupráce. Jedným z prvých krokov je porozumieť vašim obchodným operáciám a preferovanému pracovnému režimu budúceho inžiniera – či už ide o TPP, zmluvu na čiastočný úväzok, prácu ako SZČO, interim alebo čokoľvek medzi tým. Radi Vám pomôžeme pri rozhodovaní o type spolupráce.',
			},
			{
				title: 'Ako dlho trvá výberový proces?',
				description:
					'V prípade zmlúv na dobu neurčitú celý proces zvyčajne trvá v závislosti od okolností do 30 dní. V prípade freelance kontraktu vám vieme relevantných kandidátov nájsť zvyčajne do dvoch týždňov. Presný čas závisí od mnohých faktorov, preto ho nemožno zaručiť.',
			},
			{
				title: 'Ako sa spacebrains líši od iných personálnych agentúr?',
				description:
					'Náboroví pracovníci v personálnych agentúrach zvyčajne riadia nábory vo veľkom množstve – od pracovníkov na zmeny, cez technikov, elektrikárov a príležitostne aj inžinierov. Ich procesy nie sú zamerané na hlboké technické porozumenie a hodnotenie, takže kvalita kandidátov na inžinierske pozície sa môže líšiť. Náš proces je založený na internej technickej expertíze a peer-to-peer hodnotení inžinierov a technických lídrov, ktoré vám prinášajú kandidátov najvyššej kvality dostupných na trhu práce.',
			},
			{
				title: 'Ako sa spacebrains líši od tradičných pracovných portálov?',
				description:
					'Pracovné portály ako Profesia vám účtujú za vypísanie inzerátu bez akejkoľvek záruky nájdenia dobrého kandidáta. Musíte si sami spracovať všetkých kandidátov, viesť s nimi pohovory, posúdiť ich odbornosť a udržiavať komunikáciu s relevantnými kandidátmi, kým je pozícia stále otvorená. To môže pokojne zabrať aj desiatky hodín. V spacebrains sa zameriavame na komplexný nábor, takže všetko urobíme za vás, a ak nenájdeme zhodu, proces je pre vás bezplatný.',
			},
			{
				title: 'Ako funguje cenotvorba?',
				description:
					'Typ odmeňovnia závisí najmä od typu spolupráce. Zaručujeme vám, že vám budeme účtovať poplatky len vtedy, ak vám úspešne priradíme vhodného inžiniera. Inými slovami, príprava popisu práce, umiestnenie inzerátu na našom portáli, hodnotenie kandidátov a pohovory sú bezplatné.',
			},
		],
		reachOut: {
			title: 'Ozvite sa nám pre viac informácií',
			description:
				'Kontaktujte nás a získajte podrobnosti o tom, ako vám môžeme pomôcť v konkrétnych inžinierskych disciplínach, alebo ak chcete prediskutovať svoje jedinečné požiadavky. Sme pripravení pomôcť vám vybudovať silnejší a schopnejší inžiniersky tím.',
			primaryAction: 'Napíšte nám',
			secondaryAction: 'Zavolajme si',
		},
	},
	type: 'Typ',
	message: 'Správa',
	thankYou: 'Ďakujeme',
	thankYouMessage:
		'Ďakujeme, že ste nás kontaktovali. Vašu správu sme dostali a čoskoro sa vám ozveme.',
	backToHome: 'Späť na hlavnú stránku',
	agree: 'Súhlasím s <termsLink>podmienkami používania</termsLink> a <privacyLink>zásadami ochrany osobných údajov</privacyLink>',
	phone: 'Telefón',
	position: 'Pozícia',
	validationMessages: {
		required: 'Toto pole je povinné',
		firstNameRequired: 'Krstné meno je povinné',
		lastNameRequired: 'Priezvisko je povinné',
		messageRequired: 'Správa je povinná',
		typeRequired: 'Typ je povinný',
		agreementRequired: 'Súhlas je povinný',
		emailRequired: 'Email je povinný',
		emailInvalid: 'Email je neplatný',
		passwordRequired: 'Heslo je povinné',
		passwordTooShort: 'Heslo musí mať aspoň 6 znakov',
		passwordTooLong: 'Heslo môže mať maximálne 100 znakov',
		nameRequired: 'Meno je povinné',
		nameTooShort: 'Meno musí mať aspoň 3 znaky',
		nameTooLong: 'Meno môže mať maximálne 40 znakov',
		emailTooLong: 'Email môže mať maximálne 100 znakov',
		emailTooShort: 'Email musí mať aspoň 3 znaky',
		passwordsMustMatch: 'Heslá sa musia zhodovať',
		generic: 'Niečo sa pokazilo',
		emailNotFound: 'Email nebol nájdený',
		termsConditionsAgreement: 'Musíte súhlasiť s podmienkami používania',
		incorrectPassword: 'Nesprávne heslo',
		contractTypeRequired: 'Typ zmluvy je povinný',
		noticePeriodRequired: 'Výpovedná lehota je povinná',
		idealSalaryRequired: 'Ideálny plat je povinný',
		minimumSalaryRequired: 'Minimálny plat je povinný',
		accompanyingTextRequired: 'Sprievodný text je povinný',
		resumeRequired: 'Životopis je povinný',
		imageRequired: 'Fotka je povinná',
		imageTooLarge: 'Fotka musí mať menej ako 3MB',
		resumeTooLarge: 'Životopis musí mať menej ako 5MB',
		resumeInvalidType: 'Životopis musí byť vo formáte PDF',
	},
	otpEmail: {
		subject: 'Tu je tvoj verifikačný kód',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		verificationCode: 'Tvoj verifikačný kód na dokončenie registrácie:',
		verificationLink:
			'Pre dokončenie verifikácie môžeš použiť aj nasledujúci odkaz:',
		verifyButton: 'Verifikácia emailu',
		footer:
			'Tento e-mail si dostal, pretože niekto použil tvoju e-mailovú adresu na registráciu účtu na platforme spacebrains.tech. Ak si to nebol ty, ignoruj tento e-mail.',
		copyright: 'copyright 2024 © spacebrains. Všetky práva vyhradené.',
	},
	contactPage: {
		companies: {
			description:
				'Zastupujem firmu (ako manažér, HR špecialista, atď.) a chcem sa dozvedieť viac o vašich službách',
			heading: 'Píšem ako zástupca firmy',
		},
		engineers: {
			description:
				'Som inžinier, freelancer alebo technický líder a chcem sa dozvedieť viac o príležitostiach pre mňa',
			heading: 'Píšem vo svojom mene',
		},
	},
	jobsPage: {
		title: 'Aktuálne dostupné pracovné pozície',
		findOutMore: 'Zistiť viac',
	},
	loginToViewMore: 'Prihlásiť sa na zobrazenie viac',
	onboardingEmail: {
		subject: 'Registrácia dokončená',
		greeting: 'Ahoj {{firstName}},',
		welcomeMessage: 'Vitaj v spacebrains! 🚀',
		introduction:
			'My, tím spacebrains, sme radi, že ťa máme na palube. Dovoľ mi využiť túto príležitosť a predstaviť nás trochu viac.',
		platformDescription:
			'spacebrains je end-to-end platforma, ktorá spája inžinierov a technických lídrov so spoločnosťami, ktoré sa snažia obsadiť technické pozície. Platformu spacebrains sme spustili len nedávno, takže to, čo na platforme vidíš, je len zlomok všetkého, čo pre vás pripravujeme. Ale neboj sa, budeme ťa informovať, ak predstavíme nejaké nové funkcie, o ktorých by si mal/a vedieť.',
		uploadCVPrompt:
			'In the meantime, nahral/a si svoj životopis na dashboard svojho profilu? Ak nie, toto tlačidlo ťa presmeruje na miesto pre nahranie životopisu.',
		uploadCVButton: 'Nahrať CV',
		browseJobsPrompt:
			'Alebo možno chceš hneď skočiť na prehliadanie aktuálnych pozícií? Kľudne rovno cez tento odkaz tu',
		browseJobsButton: 'Prejsť na zoznam všetkých pozícií',
		gratitudeMessage:
			'A sme skutočne vďační za každú jednu registráciu u nás - či už si inžinier/ka, freelancer/ka alebo technický/á vedúca, dúfame, že ti budeme môcť slúžiť rovnako ako ostatním inžinierom, pričom budeme dbať na prirodzené rozdiely medzi každým jedným z vás.',
		contactPrompt:
			'Zostávame v kontakte a ak budeš čokoľvek potrebovať, odpovedz na tento e-mail.',
		closing: 'S pozdravom,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			"You are receiving this e-mail because someone has given your e-mail address to register an account on spacebrains.tech platform. If it wasn't you, please ignore this email.",
		copyright: 'copyright 2024 © spacebrains. All rights reserved.',
	},
	noResumeUploaded: 'Žiadny životopis nahratý',
	currentResume: 'Aktuálny životopis: {{filename}} (nahratý dňa {{createdAt}})',
	resumeDeletedDescription: 'Váš životopis bol vymazaný',
	resumeDeletedTitle: 'Životopis vymazaný',
	resumeUpdatedDescription: 'Váš životopis bol aktualizovaný',
	resumeUpdatedTitle: 'Životopis aktualizovaný',
	delete: 'Vymazať',
	download: 'Stiahnuť',
	saveResume: 'Uložiť životopis',
	upload: 'Nahrať',
	resumeUploadPrompt:
		'Kliknutím na uložiť životopis nahrať nasledujúci životopis: {{filename}}',
	partnerWithUs:
		'Spojte sa s nami a pripojte sa k ďalším spoločnostiam, ktoré myslia na budúcnosť',
	applicationEmail: {
		greeting: 'Ahoj {{name}},',
		applicationSubmitted:
			'Vaša žiadosť o pracovnú pozíciu {{jobTitle}} v spoločnosti {{companyName}} bola úspešne odoslaná. 💪',
		applicationSummary: 'Tu je sumár vašej žiadosti:',
		positionName: 'Názov pozície: {{jobTitle}}',
		contractType: 'Typ zmluvy: {{contractType}}',
		noticePeriod: 'Výpovedná lehota: {{noticePeriod}}',
		idealSalary: 'Ideálny plat: {{idealSalary}}',
		minSalary: 'Minimálny plat: {{minSalary}}',
		accompanyingText: 'Sprievodný text:',
		reviewApplication: 'Vašu žiadosť posúdime a čoskoro sa vám ozveme.',
		closing: 'S pozdravom,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			'You are receiving this e-mail because you submitted a job application on spacebrains.tech platform.',
		copyright: 'copyright 2024 © spacebrains. All rights reserved.',
		subject: 'Žiadosť o pracovnú pozíciu {{jobTitle}} v {{companyName}} bola odoslaná',
	},
	jobList: {
		loginToViewMore: 'Pre zoznam všetkých pozícií je potrebné prihlásenie',
	},
	seo: {
		home: {
			title: 'spacebrains',
			description: 'Pomáhame inžinierom nájsť ich ďalšiu obľubenú prácu.',
		},
		engineers: {
			title: 'Pre inžinierov | spacebrains',
			description: 'Pomáhame inžinierom nájsť ich ďalšiu obľubenú prácu.',
		},
		companies: {
			title: 'Pre spoločnosti | spacebrains',
			description: 'Pomáhame spoločnostiam nájsť najlepších inžinierov.',
		},
		contact: {
			title: 'Kontaktujte nás | spacebrains',
			description: 'Pomáhame inžinierom nájsť ich ďalšiu obľubenú prácu.',
		},
	},
} satisfies Locale
