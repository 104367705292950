export default {
	homepage: {
		engineerJobLanding: {
			title: 'Where engineers land their next favorite job',
			cta: 'Register as Engineer',
		},
		highlightedJobOpenings: {
			title: 'Highlight of our current job openings',
			cta: 'Browse all jobs that are relevant to you',
		},
		preVettedEngineers: {
			title: 'Choose from our portfolio of pre-vetted engineers...',
			description:
				'... save costs and progress with your projects in no time. Our expertise lies in hard tech engineering domains like manufacturing, R&D, environment, automation, chemistry, energy, electrotechnics and IT. Start now and post a job at spacebrains for free.',
			primaryAction: 'Book a call',
			secondaryAction: 'Learn more',
		},
		engineerFocus: {
			title: 'Where engineers are truly understood - join us',
			description:
				'spacebrains parter up with companies that guarantee engineer-centric conditions and freelancing options in the hard tech engineering world. Are you working in engineering, manufacturing, electrotechnical, chemical or environmental domain or somewhere in between? Apply now so we can help you bring your dream job to reality.',
			primaryAction: 'Register now',
			secondaryAction: 'Learn more',
		},
		header: {
			links: {
				home: 'Home',
				forEngineers: 'For Engineers',
				forCompanies: 'For Companies',
				contact: 'Contact',
				jobs: 'Jobs',
			},
		},
		footer: {
			description:
				'Businesses struggle to hire and keep talent and thus struggle with the ongoing projects or fail to innovate. We provide a solution. Together, we can create a vital and prospering job market ecosystem that all will benefit from.',
			office: 'Office address',
			billing: 'Billing Info',
			socials: 'Socials',
			links: {
				cookies: 'Cookies',
				privacy: 'Privacy Notice',
				terms: 'Terms & Conditions',
			},
		},
	},
	title: 'Where engineers land their next favorite job',
	iAmInterested: 'I am interested',
	iAmNotInterested: 'I am not interested',
	profile: 'Profile',
	logout: 'Logout',
	firstName: 'First Name',
	lastName: 'Last Name',
	saveChanges: 'Save Changes',
	changeEmail: 'Change Email',
	changePassword: 'Change Password',
	enable2FA: 'Enable 2FA',
	enabled2FA: '2FA is enabled',
	createPassword: 'Create Password',
	deleteAccount: 'Delete Account',
	areYouSure: 'Are you sure?',
	sendConfirmation: 'Send Confirmation',
	newEmail: 'New Email',
	emailConfirmationMessage:
		'You will receive an email at the new email address to confirm.',
	oldEmailNotice: 'An email notice will also be sent to your old address',
	twoFactorAuthenticationMessage:
		'You have not enabled two-factor authentication yet.',
	twoFactorAuthenticatorInfo1:
		'Two factor authentication adds an extra layer of security to your account. You will need to enter a code from an authenticator app like ',
	twoFactorAuthenticatorInfo2: ' to log in.',
	twoFactorAuthenticatorNotEnabled:
		'You have not enabled two-factor authentication yet.',
	currentPassword: 'Current Password',
	newPassword: 'New Password',
	confirmNewPassword: 'Confirm New Password',
	cancel: 'Cancel',
	confirm: 'Confirm',
	change: 'Change',
	logIn: 'Log In',
	rememberMe: 'Remember me',
	forgotPasswordQuestion: 'Forgot password?',
	createAccount: 'Create an Account',
	welcomeBack: 'Welcome back',
	enterDetails: 'Please enter your details.',
	email: 'Email',
	password: 'Password',
	newHere: 'New here?',
	letsStart: "Let's start your journey!",
	submit: 'Submit',
	checkYourEmail: 'Check your email',
	code: 'Code',
	emailVerificationCode: "We've sent you a code to verify your email address.",
	welcomeAboard: 'Welcome aboard!',
	pleaseEnterDetails: 'Please enter your details.',
	createAnAccount: 'Create an account',
	confirmPassword: 'Confirm Password',
	agreeTos: 'Do you agree to our Terms of Service and Privacy Policy?',
	welcome: 'Welcome',
	signupThankYouMessage: 'Thanks for signing up!',
	requireLoginMessage: 'You need to be logged in to see more jobs.',
	forgotPassword: 'Forgot Password',
	forgotPasswordMessage: 'No worries, we will send you reset instructions.',
	recoverPassword: 'Recover Password',
	backToLogin: 'Back to login',
	requiredError: '{{field}} is required',
	jobDetail: {
		chooseFile: 'Choose File',
		description: 'Description',
		details: 'Job Details',
		location: 'Location',
		salary: 'Salary',
		contractType: 'Contract Type',
		commitment: 'Commitment',
		experienceLevel: 'Experience Level',
		qualifications: 'Qualifications',
		experience: 'Experience',
		benefits: 'Benefits',
		languages: 'Languages',
		tags: 'Tags',
		companyInfo: 'Company Information',
		companyName: 'Company Name',
		industries: 'Industries',
		companyType: 'Company Type',
		companySize: 'Company Size',
		companyLocation: 'Company Location',
		alreadyApplied: 'Application Submitted',
		applyNow: 'Apply Now',
		loginToApply: 'Please log in to apply for this job',
		notSpecified: 'Not specified',
		defaultResumeAttached: 'Default Resume Attached',
		attachResume: 'Attach Resume',
		attachNewResume: 'Attach New Resume',
	},
	jobTitle: 'Job Title',
	company: 'Company',
	applicationDate: 'Application Date',
	noApplications: 'You have not applied to any jobs yet.',
	settings: {
		profile: 'Profile',
		photo: 'Photo',
		resume: 'Resume',
		email: 'Email',
		password: 'Password',
		applications: 'Applications',
	},
	preferredLanguage: 'Preferred Language',
	preferredLanguages: {
		en: 'English',
		sk: 'Slovak',
		cz: 'Czech',
	},
	idealSalary: 'Ideal Salary',
	minimumSalary: 'Minimum Salary',
	accompanyingText: 'Accompanying Text',
	contractType: 'Contract Type',
	noticePeriod: 'Notice Period',
	navigation: 'Navigation',
	referral: 'Referral',
	cookieConsent: {
		title: 'We use cookies',
		description:
			'We use cookies to ensure you get the best experience on our website. For more information on how we use cookies, please see our cookie policy.',
		agreement: 'By clicking "Accept", you agree to our use of cookies.',
		learnMore: 'Learn more.',
		accept: 'Accept',
		decline: 'Decline',
	},
	engineer: {
		engineer: 'Engineer',
		headerTitle: 'Take the next step in your engineering journey',
		headerDescription:
			'Sign up today and unlock a world of opportunities tailored just for you. Discover how spacebrains can help you achieve your professional goals and connect you with employers who value your skills and experience.',
		headerCTA: 'Register as Engineer',
		subHeader: 'Our offer and commitment to engineers',
		offers: [
			{
				title: 'Tailored opportunities',
				description:
					'Whether you’re interested in freelance, interim, or permanent roles, our platform serves to all engineers. We ensure that each job opportunity — from mechanical design and process engineering to automation, quality control and IT — is well-suited to your skills and career goals.',
			},
			{
				title: 'Transparent onboarding process',
				description:
					'We value transparency and fairness in our recruitment process. At spacebrains, you will receive clear communication at every step of your job application journey. Once you register you will be able to browse all current job openings and apply to jobs of your preference.',
			},
			{
				title: 'Community and professional growth',
				description:
					'Joining spacebrains, means becoming part of an engineering community. Our mission is to create space for engineers, where we can learn from each other and grow together. By joining spacebrains today, you will soon gain access to resources, training material and other industry insights that will help you achieve more in your career.',
			},
			{
				title: 'Uncompromised privacy and security',
				description:
					'We prioritize the confidentiality of your personal and professional information. No data will ever be shared outside our team without your explicit consent. Our stringent privacy protocols ensure your information is securely managed, allowing you full control over its use and distribution.',
			},
		],
		joinSpacebrains: {
			title: 'Join spacebrains and start building your future now!',
			cta: 'Register as Engineer',
		},
		moreInfo: {
			title: 'Do you need more information?',
			description:
				'Reach out to our team and let us help you start your journey with spacebrains. Use our contact form or book a 15-minute call with us.',
			primaryAction: 'Contact form',
			secondaryAction: 'Book a call',
		},
	},
	companies: {
		header: {
			title: 'Bringing effortless hiring experience for technical managers',
			description:
				'Skip lengthy hiring cycles and get access to growing community of engineers and leaders with our end-to-end recruiting and outsourcing platform.',
			cta: 'Book free consultation',
		},
		offers: [
			{
				title: 'Streamlined hiring',
				description:
					'From defining a job description to the final selection, our process ensures that you spend less time on hiring logistics and more time on your core business objectives.',
			},
			{
				title: 'Technical assessment',
				description:
					'Finding a match in resume is easy, real technical assessment is hard. Gain access to unrivaled excellence in technical evaluation with our unique peer-to-peer assessment process.',
			},
			{
				title: 'Commitment to quality',
				description:
					'We believe successful placement stands on both skill alignment and cultural fit. Our comprehensive vetting process includes evaluation of potential and soft skills to ensure candidates thrive in your environment.',
			},
			{
				title: 'Continued support',
				description:
					'Our relationship doesn’t end with a placement. spacebrains agents provide ongoing support and advisory services to help you manage your new hires and ensure seamless integration into your team.',
			},
		],
		faqTitle: 'Frequently asked questions',
		faq: [
			{
				title: 'What industries are you focusing on?',
				description:
					'We are best at pairing you with high expertise engineers and leaders that usually graduated from technical university - mechanical, chemical, robotics, electronics & IT, electrotechnical, process or civil engineering. This means we primarily focus on physical engineering industries such as automotive, aerospace, petrochemistry, robotics & automation, manufacturing, materials, healthtech, biotech or energy industry.',
			},
			{
				title: 'Do you only cooperate with freelancer engineers?',
				description:
					'No. We do not push companies or engineers to any arrangement. One of the first steps in the process is to understand your business operations and your preferred working mode for the future engineer - whether it is permanent contract, part-time contract, freelancing, interim or anything in between. We are happy to help you when deciding the type of cooperation.',
			},
			{
				title: 'How long does the hiring take?',
				description:
					'For permanent contracts, the whole process usually take up to 30 days, depending on the circumstances. For freelance contracts, we can match you with relevant candidates within two weeks. Exact time depends on many factors, thus it cannot be guaranteed.',
			},
			{
				title: 'How spacebrains differ from other personnel agencies?',
				description:
					'Recruiters in personnel agencies usually manage technical roles in large quantities - from shift workers, to technicians, electricians and occasionally engineers. Their processes are not focused on deep technical understanding and assessment thus the quality of candidates in engineering positions may vary. Our process is built on internal technical expertise and peer-to-peer assessment of engineers and technical leaders bringing you highest quality candidates on the job market.',
			},
			{
				title: 'How spacebrains differ from traditional job boards?',
				description:
					'Job boards charge you for job post without any guarantee of finding a good candidate. You also need to process all candidates, interview them, asses their expertise and stay on top of communication with relevant candidates while the position is still open. This can easily consume tens of hours. At spacebrains, we focus on the end-to-end recruitment, so we do everything for you, and if we don’t find a match the process is basically free.',
			},
			{
				title: 'How does the pricing work?',
				description:
					'Pricing mainly depends on the type of cooperation. What we guarantee is that we only charge you if we successfully match you with suitable engineer. In other words, preparation of job description, job board posting, assessment and interviews is for free. ',
			},
		],
		reachOut: {
			title: 'Reach out for more information',
			description:
				'For more details on how we can assist in specific engineering disciplines, or to discuss your unique requirements, please reach out. We are ready to help you build a stronger, more capable engineering team.',
			primaryAction: 'Contact form',
			secondaryAction: 'Book a call',
		},
	},
	type: 'Type',
	message: 'Message',
	thankYou: 'Thank You',
	thankYouMessage:
		'Thank you for contacting us. We have received your message and will get back to you shortly.',
	backToHome: 'Back to Home',
	position: 'Position',
	phone: 'Phone',
	agree: 'I agree to the <termsLink>terms of service</termsLink> and <privacyLink>privacy policy</privacyLink>',
	validationMessages: {
		firstNameRequired: 'First name is required',
		lastNameRequired: 'Last name is required',
		messageRequired: 'Message is required',
		emailRequired: 'Email is required',
		typeRequired: 'Type is required',
		agreementRequired: 'You must agree to the terms and conditions',
		emailInvalid: 'Email is invalid',
		required: 'This field is required',
		passwordRequired: 'Password is required',
		nameRequired: 'Name is required',
		passwordTooShort: 'Password is too short',
		passwordTooLong: 'Password is too long',
		emailTooShort: 'Email is too short',
		emailTooLong: 'Email is too long',
		nameTooShort: 'Name is too short',
		nameTooLong: 'Name is too long',
		passwordsMustMatch: 'Passwords must match',
		generic: 'Something went wrong',
		emailNotFound: 'No user exists with this email or email',
		termsConditionsAgreement:
			'You must agree to the terms of service and privacy policy',
		incorrectPassword: 'Incorrect password',
		contractTypeRequired: 'Contract type is required',
		noticePeriodRequired: 'Notice period is required',
		idealSalaryRequired: 'Ideal salary is required',
		minimumSalaryRequired: 'Minimum salary is required',
		accompanyingTextRequired: 'Accompanying text is required',
		resumeRequired: 'Resume is required',
		imageRequired: 'Image is required',
		imageTooLarge: 'Image size must be less than 3MB',
		resumeTooLarge: 'Resume size must be less than 5MB',
		resumeInvalidType: 'File must be a PDF',
	},
	otpEmail: {
		subject: 'Here is your verification code',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		verificationCode: "Here's your verification code: ",
		verificationLink: 'Or click the following button to get started:',
		verifyButton: 'Verify email',
		footer:
			"You are receiving this e-mail because someone has given your e-mail address to register an account on spacebrains.tech platform. If it wasn't you, please ignore this email.",
		copyright: 'copyright 2024 © spacebrains. All rights reserved.',
	},
	applicationEmail: {
		greeting: 'Hi {{name}},',
		applicationSubmitted:
			'Your application for the position of {{jobTitle}} at {{companyName}} has been successfully submitted. 💪',
		applicationSummary: 'Here is the summary of your application:',
		positionName: 'Position name: {{jobTitle}}',
		contractType: 'Contract type: {{contractType}}',
		noticePeriod: 'Current notice period: {{noticePeriod}}',
		idealSalary: 'Ideal salary: {{idealSalary}}',
		minSalary: 'Minimum salary: {{minSalary}}',
		accompanyingText: 'Your accompanying text:',
		reviewApplication:
			'We will review your application and get back to you soon.',
		closing: 'Best,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			'You are receiving this e-mail because you submitted a job application on spacebrains.tech platform.',
		copyright: 'copyright 2024 © spacebrains. All rights reserved.',
		subject: 'Application Submitted for {{jobTitle}} at {{companyName}}',
	},
	onboardingEmail: {
		subject: 'Registration Completed',
		greeting: 'Hi {{firstName}},',
		welcomeMessage: 'Welcome to spacebrains! 🚀',
		introduction:
			'We, the spacebrains team, are delighted to have you onboard. Let me use this opportunity to introduce us a bit more.',
		platformDescription:
			"spacebrains is an end-to-end platform that connects engineers and technical leaders with companies seeking to fill in technical positions. We launched the spacebrains platform only recently, so what you see on the platform is only a fraction of all the stuff we are preparing for you. But don't worry, we will inform you if there are any new features that you should be aware of.",
		uploadCVPrompt:
			'In the meantime, have you already uploaded your CV to your profile dashboard? If not, this button will redirect you to the place for CV upload.',
		uploadCVButton: 'Upload CV',
		browseJobsPrompt:
			'Or maybe you want to jump into browsing current job positions? Just straight through this link here',
		browseJobsButton: 'Go to Job Board',
		gratitudeMessage:
			'And, we are truly grateful for every single registration with us - whether you are an engineer, freelancer, or technical leader, we hope we will be able to serve you equally with other engineers, while minding the natural differences between every single one of you.',
		contactPrompt:
			'We stay in touch, and if you need anything, just reply to this email.',
		closing: 'Best,',
		signature: 'Partner @ spacebrains',
		imageAlt: 'Image description',
		imageTitle: 'Image title',
		disclaimer:
			"You are receiving this e-mail because someone has given your e-mail address to register an account on the spacebrains.tech platform. If it wasn't you, please ignore this email.",
		copyright: 'copyright 2024 © spacebrains. All rights reserved.',
	},
	contactPage: {
		engineers: {
			heading: 'Writing on my behalf',
			description:
				'I am engineer, freelancer or technical leader that is looking for more information about opportunities for me',
		},
		companies: {
			heading: 'Writing on company behalf',
			description:
				'I am representing a company (as manager, HR representative, etc.), looking for more information about your services',
		},
	},
	jobsPage: {
		title: 'Currently Available Jobs',
		findOutMore: 'Find out more',
	},
	loginToViewMore: 'Log in to view more',
	noResumeUploaded: 'No resume uploaded',
	currentResume: 'Current resume: {{filename}} (uploaded on {{createdAt}})',
	resumeDeletedDescription: 'Your resume has been deleted',
	resumeDeletedTitle: 'Resume deleted',
	resumeUpdatedDescription: 'Your resume has been updated',
	resumeUpdatedTitle: 'Resume updated',
	delete: 'Delete',
	download: 'Download',
	saveResume: 'Save resume',
	upload: 'Upload',
	resumeUploadPrompt:
		'Please click save resume to upload the following resume file: {{filename}}',
	partnerWithUs: 'Partner with us and join other future-thinking businesses',
	jobList: {
		loginToViewMore: 'To see the full list of open positions please log in',
	},
	seo: {
		home: {
			title: 'spacebrains',
			description: 'Helping engineers land their next favorite job.',
		},
		engineers: {
			title: 'For Engineers | spacebrains',
			description: 'Helping engineers land their next favorite job.',
		},
		companies: {
			title: 'For Companies | spacebrains',
			description: 'Helping companies find the best engineers.',
		},
		contact: {
			title: 'Contact Us | spacebrains',
			description: 'Helping engineers land their next favorite job.',
		},
	},
}
